import React from "react";

export const LeftArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1679 5.83211C9.79786 5.20214 10.875 5.64831 10.875 6.53921L10.875 9.50001C10.875 9.77615 11.0989 10 11.375 10L16 10C16.2761 10 16.5 10.2239 16.5 10.5L16.5 13.5C16.5 13.7761 16.2761 14 16 14L11.375 14C11.0989 14 10.875 14.2239 10.875 14.5L10.875 17.4608C10.875 18.3517 9.79787 18.7979 9.1679 18.1679L3.70711 12.7071C3.31658 12.3166 3.31658 11.6834 3.70711 11.2929L9.1679 5.83211ZM21 20C21 20.5523 20.5523 21 20 21L18.625 21C18.0727 21 17.625 20.5523 17.625 20L17.625 4C17.625 3.44772 18.0727 3 18.625 3L20 3C20.5523 3 21 3.44772 21 4L21 20Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

LeftArrow.displayName = "LeftArrow";
