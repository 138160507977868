import React from "react";

export const RightArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.8321 5.83211C14.2021 5.20214 13.125 5.64831 13.125 6.53921L13.125 9.50001C13.125 9.77615 12.9011 10 12.625 10L8 10C7.72385 10 7.5 10.2239 7.5 10.5L7.5 13.5C7.5 13.7761 7.72385 14 8 14L12.625 14C12.9011 14 13.125 14.2239 13.125 14.5L13.125 17.4608C13.125 18.3517 14.2021 18.7979 14.8321 18.1679L20.2929 12.7071C20.6834 12.3166 20.6834 11.6834 20.2929 11.2929L14.8321 5.83211ZM3 20C3 20.5523 3.44772 21 4 21L5.375 21C5.92728 21 6.375 20.5523 6.375 20L6.375 4C6.375 3.44772 5.92728 3 5.375 3L4 3C3.44771 3 3 3.44772 3 4L3 20Z"
      fill="var(--svg-fill, currentColor)"
    />
  </svg>
);

RightArrow.displayName = "RightArrow";
