import { action, computed, observable, when } from "mobx";
import chunk from "lodash/chunk";
import { defineMessages } from "react-intl";
import { RootStore } from "@web/stores";
import { EntryModel, RequiredTagModel, SectionModel } from "@web/models";
import { Api } from "@web/api";
import PubSub, { AddEvent, Subscriber } from "@web/stores/PubSub";
import commonStoreTexts from "./texts";
import { DocumentBucket, EntryBucket } from "@web/models/BucketModel";

export class ViewStore {
  @observable public isDocumentSidebarHidden = false;

  @action.bound
  public hideDocumentSidebar() {
    this.isDocumentSidebarHidden = true;
  }

  @action.bound
  public showDocumentSidebar() {
    this.isDocumentSidebarHidden = false;
  }
}
