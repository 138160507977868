import { Api } from "@web/api";
import { KeyboardStore } from "@web/stores/KeyboardStore";
import { ClassificationStore } from "@web/stores/ClassificationStore";
import { MultiSelectStore } from "@web/stores/MultiSelectStore";
import { generateUrlQueryString } from "@web/utils/URLHelpers";
import { AppConfig } from "@config/index";
import { DocumentStore } from "./DocumentStore";
import { ResultStore } from "./ResultStore";
import { RecordStore } from "./RecordStore";
import { FilterStore } from "./FilterStore";
import { UploadStore } from "./UploadStore";
import { MessageStore } from "./MessageStore";
import { SearchStore } from "./SearchStore";
import { SectionStore } from "./SectionStore";
import { TagStore } from "./TagStore";
import { CommentStore } from "./CommentStore";
import { AttributeStore } from "./AttributeStore";
import { FlowStore } from "./FlowStore";
import { AccessControlStore } from "./AccessControlStore";
import { ReminderStore } from "./ReminderStore";
import { DocumentVersionStore } from "./DocumentVersionStore";
import { ViewStore } from "@web/stores/ViewStore";

export class RootStore {
  messageStore: MessageStore;
  attributeStore: AttributeStore;
  tagStore: TagStore;
  classificationStore: ClassificationStore;
  flowStore: FlowStore;
  sectionStore: SectionStore;
  filterStore: FilterStore;
  searchStore: SearchStore;
  documentStore: DocumentStore;
  documentVersionStore: DocumentVersionStore;
  commentStore: CommentStore;
  recordStore: RecordStore;
  reminderStore: ReminderStore;
  resultStore: ResultStore;
  uploadStore: UploadStore;
  multiSelectStore: MultiSelectStore;
  keyboardStore: KeyboardStore;
  accessControlStore: AccessControlStore;
  viewStore: ViewStore;

  constructor(private config: AppConfig, private api: Api) {
    this.messageStore = new MessageStore();
    this.attributeStore = new AttributeStore(api, this, config);
    this.flowStore = new FlowStore(api);
    this.tagStore = new TagStore(api, this);
    this.classificationStore = new ClassificationStore(api, this);
    this.sectionStore = new SectionStore(api, this, localStorage);
    this.filterStore = new FilterStore(api, this, localStorage);
    this.searchStore = new SearchStore(api, this, localStorage);
    this.documentStore = new DocumentStore(api, this, config);
    this.documentVersionStore = new DocumentVersionStore(api, this);
    this.commentStore = new CommentStore(api, this, config.loggedInUser.id);
    this.recordStore = new RecordStore(api, this, config);
    this.reminderStore = new ReminderStore(api, this);
    this.resultStore = new ResultStore(api, this, localStorage);
    this.uploadStore = new UploadStore(this.api, this, config.allowDuplication);
    this.multiSelectStore = new MultiSelectStore(api, this);
    this.accessControlStore = new AccessControlStore(api, this);
    this.keyboardStore = new KeyboardStore();
    this.viewStore = new ViewStore();
  }

  currentSearchURL = () => {
    const { tags, customFilters, tagStatus, attributes } =
      this.filterStore.searchParameters;
    const { sectionId } = this.sectionStore.searchParameters;
    const { query, order } = this.searchStore.searchParameters;

    const sectionUrl = sectionId ? `/section/${sectionId}` : "";

    const queryString = generateUrlQueryString({
      query,
      tags,
      attributes,
      customFilters,
      tagStatus,
      order,
    });

    return queryString.length > 0 ? `${sectionUrl}?${queryString}` : sectionUrl;
  };
}

export * from "./DocumentStore";
export * from "./RecordStore";
export * from "./ResultStore";
export * from "./FilterStore";
export * from "./UploadStore";
export * from "./ClassificationStore";
export * from "./MessageStore";
export * from "./SearchStore";
export * from "./SectionStore";
export * from "./TagStore";
export * from "./MultiSelectStore";
export * from "./CommentStore";
export * from "./FlowStore";
export * from "./AttributeStore";
export * from "./AccessControlStore";
export * from "./ReminderStore";
export * from "./DocumentVersionStore";
export * from "./ViewStore";
